<template>
  <div class="wrap__content">
    <div class="content__page content__page--margintop">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 offset-xl-4 col-md-6 offset-md-3">
            <div class="content__page--back">
              <a @click="$router.go(-1)">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 12H5"
                    stroke="#0E1839"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 19L5 12L12 5"
                    stroke="#0E1839"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {{ $t('system.back') }}
              </a>
            </div>

            <div class="content__title--block">
              <div
                class="content__title section__title"
                v-html="$t('auth.title')"
              ></div>
              <div class="content__title--text">
                {{ $t('auth.desc') }}
              </div>
            </div>

            <div class="login__block">
              <div class="login__tabs">
                <div
                  class="login__tab--item"
                  v-bind:class="{ 'login__tab--active': ecpSign == false }"
                >
                  <a @click="ecpSign = false">
                    {{ $t('auth.tab.ecp') }}
                  </a>
                </div>
                <div
                  class="login__tab--item"
                  v-bind:class="{ 'login__tab--active': ecpSign == true }"
                >
                  <a @click="ecpSign = true">
                    {{ $t('auth.tab.login') }}
                  </a>
                </div>
              </div>

              <div class="login__block--form">
                <div class="row">
                  <div class="col-lg-12">
                    <div
                      class="login__block--tab"
                      v-bind:class="{
                        'login__block--active': ecpSign == false,
                      }"
                      v-if="ecpSign == false"
                    >
                      <!--<form method="POST" @submit.prevent="onSubmitPki">-->
                      <div class="form__block--line">
                        <div class="form__block--input input--error">
                          <label class="form__file" @click="onPki">
                            <div class="form__file--title">
                              {{ $t('auth.input.ecp') }}
                              <img src="../assets/img/file-icon.svg" />
                            </div>
                            <!--<input type="file" class="input-linck" required="" name="file">-->
                          </label>
                          <span
                            style="
                              padding: 20px 0 0 0;
                              display: block;
                              text-align: center;
                              color: red;
                            "
                            v-if="message.code == 401"
                            >{{ $t('auth.required.error') }}</span
                          >
                          <span
                            style="
                              padding: 20px 0 0 0;
                              display: block;
                              text-align: center;
                              color: red;
                            "
                            v-if="message.code == 402"
                            >{{ $t('auth.access.error') }}</span
                          >
                          <!--<span class="input-required input--error">{{ $t('auth.required.error') }}</span>-->
                        </div>
                      </div>
                      <!--
												<div class="form__block--line">
													<div class="form__block--input">
														<div class="form__block--title">
															{{ $t('auth.input.ecp_password') }}
														</div>
														<input type="password" class="input-linck" required="" name="password" value="">
														<span class="input-required">{{ $t('auth.required.error') }}</span>
													</div>
												</div>

											-->
                      <!--
												<div class="form__listitem--line form__listitem--button">
													<div class="form__listitem--input">
														<button class="input__button" type="submit">Войти</button>
													</div>
												</div>
											-->
                      <!--</form>-->
                    </div>
                    <div
                      class="login__block--tab"
                      v-bind:class="{ 'login__block--active': ecpSign == true }"
                      v-if="ecpSign == true"
                    >
                      <form method="POST" @submit.prevent="onSubmit">
                        <div class="form__block--line">
                          <div class="form__block--input">
                            <div class="form__block--title">
                              {{ $t('auth.input.email') }}
                            </div>
                            <input
                              type="text"
                              class="input-linck"
                              :class="[
                                message.code == 401 ? 'input--error' : '',
                              ]"
                              required=""
                              name="username"
                              value=""
                            />
                            <!--  -->
                            <span class="input-required">{{
                              $t('auth.required.error')
                            }}</span>
                          </div>
                        </div>
                        <div class="form__block--line">
                          <div class="form__block--input">
                            <div class="form__block--title">
                              {{ $t('auth.input.password') }}
                            </div>
                            <input
                              type="password"
                              class="input-linck"
                              required=""
                              name="password"
                              value=""
                            />
                            <!-- input--error -->
                            <span class="input-required">{{
                              $t('auth.required.error')
                            }}</span>
                          </div>
                        </div>
                        <div
                          class="form__listitem--line form__listitem--button"
                        >
                          <div class="form__listitem--input">
                            <button class="input__button">
                              {{ $t('auth.login') }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form__listitem--registr">
                      <a :href="'/' + $i18n.locale + '/registration'">{{
                        $t('system.registration')
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../boot/axios'
import axios from 'axios'

export default {
  name: 'Auth',
  data() {
    return {
      ecpSign: true,
      token: null,
      message: {
        code: null,
      },
      loading: null,
    }
  },
  head: {
    title() {
      return {
        inner:
          this.$i18n.locale == 'kz'
            ? 'Авторизация | «eQonaq» мамандандырылған ақпараттық жүйесі'
            : this.$i18n.locale == 'ru'
              ? 'Авторизация | Специализированная информационная система «eQonaq»'
              : 'Authorization | Specialized information system "eQonaq"',
      }
    },
    meta: [{ name: 'description', content: 'My description' }],
  },
  beforeCreate() {
    //

    /*

		if (localStorage.token) {
			delete localStorage.token;

			location.reload();
		}

		*/

    if (localStorage.token) {
      let user = JSON.parse(localStorage.user)

      if (
        ['tour-operator-entity', 'tour-operator-individual'].includes(
          user.role.slug,
        )
      ) {
        this.$router.push('/' + this.$i18n.locale + '/account')
      }

      if (
        ['administrator', 'executor', 'stationery', 'accountant'].includes(
          user.role.slug,
        )
      ) {
        this.$router.push('/' + this.$i18n.locale + '/administration')
      }
    }
  },
  methods: {
    onSubmit(evt) {
      const formData = new FormData(evt.target)

      //  formData.append('lang', this._i18n.locale);

      //  this.loading = true;

      api
        .post('login', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            localStorage.setItem('token', 'Bearer ' + response.data.data.token)
            localStorage.setItem(
              'user',
              JSON.stringify(response.data.data.user),
            )

            api.defaults.headers.common['authorization'] =
              'Bearer ' + response.data.data.token

            this.token = response.data.data.token

            if (
              ['tour-operator-entity', 'tour-operator-individual'].includes(
                response.data.data.role.slug,
              )
            ) {
              this.$router.push('/' + this.$i18n.locale + '/account')
            }

            if (
              [
                'administrator',
                'executor',
                'stationery',
                'accountant',
              ].includes(response.data.data.role.slug)
            ) {
              this.$router.push('/' + this.$i18n.locale + '/administration')
            }
          }

          if (response.data.code == 401) {
            this.loading = false

            this.message.code = 401
          }

          if (response.data.code == 422) {
            this.loading = false

            this.message = true
          }
        })
    },
    onPki() {
      const websocket = new WebSocket('wss://127.0.0.1:13579/')

      var ready = null

      let ecpData = {
        path: null,
        owner: null,
        data: null,
      }

      websocket.onopen = () => {
        ready = true

        handle()
      }

      websocket.onclose = (e) => {
        if (e.wasClean) {
          console.log('connection closed')
        } else {
          if (this.$i18n.locale == 'kz')
            alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
          if (this.$i18n.locale == 'ru')
            alert('Убедитесь, что программа NCALayer запущена')
          if (this.$i18n.locale == 'en')
            alert('Make sure that the NCALayer program is running')

          console.log('error?')
          //setErrormodal(true);
          setTimeout(() => {
            //setErrormodal(false);
          }, 5000)
        }
      }

      function handle() {
        if (!ready) {
          alert('Убедитесь, что программа NCALayer запущена')
        } else {
          const data = {
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: [
              'PKCS12',
              'AUTHENTICATION',
              '<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
              '',
              '',
            ],
          }
          websocket.send(JSON.stringify(data))

          //	SIGNATURE
          //	AUTHENTICATION
        }
      }

      websocket.onmessage = (e) => {
        const data1 = JSON.parse(e.data)
        ecpData.data = data1.responseObject
        if (
          typeof ecpData.data === 'string' ||
          ecpData.data instanceof String
        ) {
          const formData = new FormData()

          formData.append('ecp', ecpData.data)

          api
            .post('login-pki', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              if (response.data.code == 200) {
                localStorage.setItem(
                  'token',
                  'Bearer ' + response.data.data.token,
                )
                localStorage.setItem(
                  'user',
                  JSON.stringify(response.data.data.user),
                )

                api.defaults.headers.common['authorization'] =
                  'Bearer ' + response.data.data.token

                this.token = response.data.data.token

                if (
                  ['tour-operator-entity', 'tour-operator-individual'].includes(
                    response.data.data.role.slug,
                  )
                ) {
                  this.$router.push('/' + this.$i18n.locale + '/account')
                }

                if (
                  [
                    'administrator',
                    'executor',
                    'stationery',
                    'accountant',
                  ].includes(response.data.data.role.slug)
                ) {
                  this.$router.push('/' + this.$i18n.locale + '/administration')
                }
              }

              if (response.data.code == 401) {
                this.loading = false

                this.message.code = 401
              }

              if (response.data.code == 402) {
                this.loading = false

                this.message.code = 402
              }

              if (response.data.code == 422) {
                this.loading = false

                this.message = true
              }
            })
        }
      }
    },
    onSubmitPki(evt) {
      const formData = new FormData(evt.target)

      axios
        .post('registerpki', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.data.code == 200) {
            localStorage.setItem('token', 'Bearer ' + response.data.data.token)

            api.defaults.headers.common['authorization'] =
              'Bearer ' + response.data.data.token

            this.token = response.data.data.token

            this.$router.push('/ru/account')
          }
        })
    },
  },
}
</script>

<style scoped></style>
